@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

// Color Variables
$primary-blue: #4a90e2;
$primary-red: #e74c3c;
$secondary-color: #f5f7fa;
$accent-color: #ff6b6b;
$text-color: #333;
$light-text-color: #777;
$white: #ffffff;

// Layout Variables
$sidebar-width: 240px;
$header-height: 70px;

// Mixins
@mixin transition($property: all, $duration: 0.3s, $timing: ease) {
  transition: $property $duration $timing;
}

@mixin box-shadow($shadow...) {
  box-shadow: $shadow;
}

@mixin gradient-background($start-color, $end-color) {
  background: linear-gradient(135deg, $start-color, $end-color);
}

// Global Styles
body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background-color: $secondary-color;
  color: $text-color;
  font-size: 16px;
  line-height: 1.6;
}

.elegant-dashboard {
  display: flex;
  height: 100vh;
}
.top-bar-logo {
  height: 40px;
  margin-left: 15px;
}

.sidebar-logo {
  max-width: 80%;
  height: auto;
  margin-bottom: 20px;
}
// Sidebar Styles
.elegant-sidebar {
  width: $sidebar-width;
  @include gradient-background($primary-blue, darken($primary-blue, 15%));
  @include box-shadow(2px 0 20px rgba(0, 0, 0, 0.2));
  display: flex;
  flex-direction: column;
  @include transition;

  .elegant-logo {
    font-size: 28px;
    font-weight: 600;
    color: $white;
    padding: 25px 20px;
    text-align: center;
    letter-spacing: 1px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  }
  .elegant-nav {
    flex-grow: 1;
  
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
  
      li {
        padding: 15px 25px;
        cursor: pointer;
        @include transition(background-color);
        display: flex;
        align-items: center;
  
        &:hover,
        &.active {
          background-color: rgba($white, 0.1);
          color: $white; // Add this line to make the text white when the li is active
        }
  
        svg {
          margin-right: 15px;
          font-size: 18px;
          color: $white;
        }
  
        span {
          font-weight: 500;
          color: $white;
        }
      }
    }
  }
  .navigation {
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 8px;
  }
  
  .tab-list {
    gap: 1rem;
    list-style-type: none;
    padding: 0;
  }
  
  .tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 1rem;
    border-radius: 4px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
  }
  
  .tab:hover {
    background-color: #f0f0f0;
  }
  
  .active {
    background-color: #007bff;
    color: white;
    transform: scale(1.05);
  }
  
  .tab span {
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
  
  @media (max-width: 768px) {
    .tab-list {
      flex-wrap: wrap;
      gap: 0.5rem;
    }
  
    .tab {
      width: 33%;
    }
  }
  
  .elegant-logout {
    padding: 20px 25px;
    cursor: pointer;
    border-top: 1px solid rgba($white, 0.1);
    display: flex;
    align-items: center;
    color: $white;
    @include transition(background-color);

    &:hover {
      background-color: rgba($white, 0.1);
    }

    svg {
      margin-right: 15px;
      font-size: 18px;
    }
  }
}

// Content Styles
.elegant-content {
  flex-grow: 1;
  padding: 30px;
  overflow-y: auto;

  .elegant-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    h1 {
      font-size: 32px;
      font-weight: 600;
      @include gradient-background($primary-blue, $primary-red);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .elegant-notifications-icon {
      font-size: 24px;
      cursor: pointer;
      position: relative;
      color: $light-text-color;
      @include transition(color);

      &:hover {
        color: $primary-red;
      }

      .elegant-notification-badge {
        position: absolute;
        top: -8px;
        right: -8px;
        background-color: $accent-color;
        color: $white;
        border-radius: 50%;
        padding: 2px 6px;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
}

// Notifications Dropdown
.elegant-notifications-dropdown {
  position: absolute;
  top: $header-height;
  right: 30px;
  background-color: $white;
  @include box-shadow(0 5px 20px rgba(0, 0, 0, 0.15));
  border-radius: 8px;
  padding: 20px;
  width: 350px;
  max-height: 450px;
  overflow-y: auto;
  z-index: 1000;

  h3 {
    margin-top: 0;
    margin-bottom: 15px;
    @include gradient-background($primary-blue, $primary-red);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
  }

  .elegant-notification {
    padding: 12px 0;
    border-bottom: 1px solid #eee;

    &:last-child {
      border-bottom: none;
    }

    p {
      margin: 0 0 5px 0;
      font-weight: 500;
    }

    small {
      color: $light-text-color;
      font-size: 12px;
    }
  }
}

// Inspections Section
.elegant-inspections-section {
  background-color: $white;
  border-radius: 8px;
  padding: 25px;
  @include box-shadow(0 5px 20px rgba(0, 0, 0, 0.08));

  h2 {
    margin-top: 0;
    margin-bottom: 25px;
    @include gradient-background($primary-blue, $primary-red);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;

    th, td {
      text-align: left;
      padding: 15px;
    }

    th {
      background-color: $secondary-color;
      font-weight: 600;
      @include gradient-background($primary-blue, $primary-red);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 0.5px;
    }

    td {
      background-color: $white;
      @include box-shadow(0 2px 10px rgba(0, 0, 0, 0.05));
    }

    tr:hover td {
      @include box-shadow(0 2px 15px rgba(0, 0, 0, 0.1));
    }

    .elegant-button {
      @include gradient-background($primary-blue, $primary-red);
      color: $white;
      border: none;
      padding: 10px 15px;
      border-radius: 5px;
      cursor: pointer;
      @include transition(background-color);
      font-weight: 500;

      &:hover {
        @include gradient-background(darken($primary-blue, 10%), darken($primary-red, 10%));
      }

      &:disabled {
        background: #ccc;
        cursor: not-allowed;
      }
    }
  }
}

// Error Message
.elegant-error-message {
  background-color: #ffebee;
  color: #c62828;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 25px;
  font-weight: 500;
  @include box-shadow(0 2px 10px rgba(198, 40, 40, 0.1));
}

// Animations
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

.elegant-notifications-dropdown, .elegant-inspections-section {
  animation: fadeIn 0.3s ease;
}

// Responsive Design
@media (max-width: 1024px) {
  .elegant-sidebar {
    width: 200px;
  }

  .elegant-content {
    padding: 20px;
  }
}

@media (max-width: 768px) {
  .elegant-dashboard {
    flex-direction: column;
  }

  .elegant-sidebar {
    width: 100%;
    height: auto;
  }

  .elegant-content {
    padding: 15px;
  }

  .elegant-notifications-dropdown {
    width: calc(100% - 30px);
    right: 15px;
  }
}