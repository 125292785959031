.profile-container {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  
    &:hover {
      box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    }
  }
  
  .profile-title {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .profile-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  
    label {
      font-weight: bold;
      color: #555;
    }
  
    input {
      padding: 0.75rem;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 1rem;
      transition: border-color 0.3s ease;
  
      &:focus {
        outline: none;
        border-color: #007bff;
      }
    }
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .btn {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
  
    &:hover {
      transform: translateY(-2px);
    }
  
    &:active {
      transform: translateY(0);
    }
  }
  
  .btn-save {
    background-color: #007bff;
    color: #fff;
  
    &:hover {
      background-color: #0056b3;
    }
  }
  
  .btn-cancel {
    background-color: #6c757d;
    color: #fff;
  
    &:hover {
      background-color: #545b62;
    }
  }
  
  .btn-edit {
    background-color: #28a745;
    color: #fff;
  
    &:hover {
      background-color: #218838;
    }
  }
  
  .profile-info {
    p {
      margin-bottom: 0.75rem;
      font-size: 1.1rem;
      color: #333;
  
      strong {
        font-weight: bold;
        margin-right: 0.5rem;
      }
    }
  }
  
  .profile-loading, .profile-error {
    text-align: center;
    font-size: 1.2rem;
    color: #6c757d;
    margin-top: 2rem;
  }
  
  .profile-error {
    color: #dc3545;
  }