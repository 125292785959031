@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* Variables */
:root {
  --iot-primary-color: #6a11cb;
  --iot-secondary-color: #2575fc;
  --iot-accent-color: #ffa17f;
  --iot-danger-color: #ff4757;
  --iot-background-color: #f8f9fa;
  --iot-text-color: #2d3436;
  --iot-border-radius: 10px;
  --iot-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

/* Mixins */
@mixin iot-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin iot-button($bg-color, $text-color) {
  background-color: $bg-color;
  color: $text-color;
  border: none;
  padding: 12px 24px;
  border-radius: var(--iot-border-radius);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 7px 14px rgba(0, 0, 0, 0.1);
  }
}

/* Global Styles */
.iot-body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--iot-background-color);
  color: #2d3436;
  line-height: 1.6;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Shared Component Styles */
.iot-component-container {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #fff;
  border-radius: var(--iot-border-radius);
  box-shadow: var(--iot-box-shadow);
  overflow: hidden;
  transform: translateY(20px);
  opacity: 0;
  animation: iot-fadeInUp 0.5s ease forwards;

  @keyframes iot-fadeInUp {
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(to right, var(--iot-primary-color), var(--iot-secondary-color));
  }
}

.iot-heading {
  text-align: center;
  color: var(--iot-primary-color);
  margin-bottom: 1.5rem;
  font-weight: 600;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 50px;
    height: 2px;
    background-color: var(--iot-accent-color);
    margin: 10px auto 0;
  }
}

/* Form Styles */
.iot-form-group {
  margin-bottom: 1.25rem;
  position: relative;

  .iot-label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: lighten(#2d3436, 10%);
    transition: all 0.3s ease;
  }

  .iot-input,
  .iot-select {
    width: 100%;
    padding: 12px;
    border: 1px solid #e1e8ed;
    border-radius: var(--iot-border-radius);
    font-size: 0.95rem;
    transition: all 0.3s ease;

    &:focus {
      outline: none;
      border-color: var(--iot-secondary-color);
      box-shadow: 0 0 0 3px rgba(var(--iot-secondary-color), 0.1);

      + .iot-label {
        transform: translateY(-30px);
        font-size: 0.85rem;
        color: var(--iot-primary-color);
      }

      ~ .iot-form-icon {
        color: var(--iot-primary-color);
      }
    }
  }

  .iot-form-icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    color: lighten(#2d3436, 30%);
    transition: all 0.3s ease;
  }
}

/* Button Styles */
.iot-btn-primary {
  @include iot-button(var(--iot-primary-color), #fff);
  width: 100%;
  font-size: 1rem;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--iot-accent-color);
    transform: translateX(-100%);
    transition: all 0.3s ease;
  }

  &:hover::before {
    transform: translateX(0);
  }
}

.iot-btn-secondary {
  @include iot-button(var(--iot-secondary-color), #fff);
}

.iot-btn-danger {
  @include iot-button(var(--iot-danger-color), #fff);
}

/* AddDevice Component */
.iot-add-device {
  @extend .iot-component-container;
  max-width: 450px;
  background-image: linear-gradient(to bottom right, rgba(var(--iot-primary-color), 0.05), rgba(var(--iot-secondary-color), 0.05));
  backdrop-filter: blur(10px);
  border: 1px solid rgba(var(--iot-primary-color), 0.1);
}

/* DeviceDetails Component */
.iot-device-details {
  @extend .iot-component-container;
  max-width: 550px;

  .iot-device-info {
    margin-bottom: 2rem;

    .iot-info-item {
      margin-bottom: 0.75rem;
      font-size: 1rem;

      .iot-info-label {
        font-weight: 600;
        margin-right: 0.5rem;
        color: var(--iot-primary-color);
      }
    }

    .iot-status {
      display: inline-block;
      padding: 5px 10px;
      border-radius: 20px;
      font-size: 0.85rem;
      font-weight: 500;

      &.iot-active { 
        background-color: var(--iot-secondary-color); 
        color: #fff; 
        box-shadow: 0 2px 5px rgba(37, 87, 252, 0.2);
      }
      &.iot-inactive { 
        background-color: var(--iot-danger-color); 
        color: #fff; 
        box-shadow: 0 2px 5px rgba(255, 71, 87, 0.2);
      }
    }
  }
}

/* DeviceList Component */
.iot-device-list {
  @extend .iot-component-container;

  .iot-btn-primary {
    display: block;
    margin: 0 auto 2rem;
    font-size: 1rem;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: -15px;
      left: 50%;
      transform: translateX(-50%);
      width: 30px;
      height: 30px;
      background-color: var(--iot-accent-color);
      border-radius: 50%;
      box-shadow: 0 5px 10px rgba(255, 161, 127, 0.2);
      animation: iot-pulse 2s infinite ease-in-out;
    }

    @keyframes iot-pulse {
      0% { transform: scale(1); opacity: 1; }
      100% { transform: scale(1.5); opacity: 0; }
    }
  }

  .iot-device-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1.5rem;
  }

  .iot-device-card {
    background-color: #fff;
    border-radius: var(--iot-border-radius);
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    border: 1px solid rgba(var(--iot-primary-color), 0.1);

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
    }

    .iot-device-name {
      color: var(--iot-primary-color);
      margin-bottom: 1rem;
      font-weight: 600;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 40px;
        height: 2px;
        background-color: var(--iot-accent-color);
      }
    }
    .iot-qr-code-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
    
      img {
        max-width: 200px;
        margin: 20px 0;
      }
    }
    .iot-device-info {
      margin-bottom: 0.5rem;
      font-size: 0.9rem;
      color: lighten(#2d3436, 15%);
    }

    .iot-status {
      display: inline-block;
      padding: 4px 10px;
      border-radius: 20px;
      font-size: 0.8rem;
      font-weight: 500;
      margin-top: 0.75rem;

      &.iot-active { 
        background-color: var(--iot-secondary-color); 
        color: #fff; 
        box-shadow: 0 2px 5px rgba(37, 87, 252, 0.2);
      }
      &.iot-inactive { 
        background-color: var(--iot-danger-color); 
        color: #fff; 
        box-shadow: 0 2px 5px rgba(255, 71, 87, 0.2);
      }
    }
.iot-qr-code-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;

  img {
    max-width: 200px;
    margin: 10px 0;
  }

  p {
    text-align: center;
    font-style: italic;
    color: #666;
  }
}
    .iot-btn-secondary {
      display: block;
      width: 100%;
      margin-top: 1.25rem;
      text-align: center;
      text-decoration: none;
      font-size: 0.9rem;
      position: relative;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 2px;
        background-color: var(--iot-accent-color);
        transform: translateX(-100%);
        transition: all 0.3s ease;
      }

      &:hover::before {
        transform: translateX(0);
      }
    }
  }
}

/* Shared styles */
.iot-loading,
.iot-error-message {
  text-align: center;
  font-size: 1.1rem;
  margin-top: 2rem;
  color: lighten(#2d3436, 20%);
}

.iot-error-message {
  color: var(--iot-danger-color);
}