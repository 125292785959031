// Colors
$primary-color: #6c5ce7;
$secondary-color: #a29bfe;
$background-color: #f4f7f9;
$background-color-light: #ffffff;
$text-color: #2d3436;
$text-color-light: #636e72;
$error-color: #d63031;
$error-color-light: #fab1a0;

// Typography
$font-family: 'Poppins', sans-serif;
$font-size-base: 16px;
$font-size-large: 20px;
$font-size-xlarge: 28px;
$font-size-small: 14px;

// Spacing
$spacing-small: 8px;
$spacing-medium: 16px;
$spacing-large: 24px;

// Borders
$border-radius: 8px;
$border-radius-large: 16px;

// Transitions
$transition-speed: 0.3s;