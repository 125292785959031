@import '../../styles/variables';

// Colors
$red: rgb(167, 5, 5);
$blue: #007bff;
$white: #ffffff;
$black: #000000;

// Mixins
@mixin glass-effect {
  background: rgba($white, 0.1);
  backdrop-filter: blur(5px);
  border: 1px solid rgba($white, 0.2);
}

@mixin neon-shadow($color) {
  box-shadow: 0 0 5px rgba($color, 0.3);
}

// Keyframes
@keyframes gradient-animation {
  0%, 100% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
}

.auth {
  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(-45deg, $blue, $red, darken($blue, 15%), darken($red, 15%));
    background-size: 300% 300%;
    animation: gradient-animation 30s ease infinite;
    padding: 1rem;
  }

  &-card {
    @include glass-effect;
    padding: 2rem;
    border-radius: 20px;
    width: 100%;
    max-width: 320px;
    transition: all 0.3s ease;
    @include neon-shadow($blue);

    &:hover {
      @include neon-shadow($red);
    }
  }

  &-header {
    text-align: center;
    margin-bottom: 1.5rem;

    &__logo {
      width: 120px;
      margin-bottom: 1rem;
    }

    &__title {
      color: $white;
      font-size: 1.8rem;
      margin-bottom: 0.5rem;
      font-weight: 700;
      text-shadow: 1px 1px 2px rgba($black, 0.3);
    }

    &__subtitle {
      color: rgba($white, 0.9);
      font-size: 0.9rem;
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__group {
      margin-bottom: 1.25rem;
      width: 100%;
      max-width: 280px;

      label {
        display: block;
        color: $white;
        margin-bottom: 0.4rem;
        font-weight: 600;
        font-size: 0.85rem;
      }

      input {
        width: 100%;
        padding: 0.7rem;
        border: 1px solid rgba($white, 0.3);
        border-radius: 8px;
        background-color: rgba($white, 0.1);
        color: $white;
        font-size: 0.85rem;
        transition: border-color 0.3s ease;

        &::placeholder {
          color: rgba($white, 0.6);
        }

        &:focus {
          outline: none;
          border-color: $red;
        }
      }
    }

    &__button {
      width: 100%;
      max-width: 280px;
      padding: 0.7rem;
      background-color: $red;
      color: $white;
      border: none;
      border-radius: 20px;
      font-size: 0.95rem;
      font-weight: bold;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: darken($red, 10%);
      }

      &:active {
        transform: translateY(1px);
      }
    }
  }

  &-switch {
    text-align: center;
    margin-top: 1.25rem;
    font-size: 0.85rem;
    color: rgba($white, 0.9);

    a {
      color: $white;
      text-decoration: none;
      font-weight: bold;
      transition: color 0.3s ease;

      &:hover {
        color: $red;
      }
    }
  }
}

.error-message {
  background-color: rgba($red, 0.2);
  color: $white;
  padding: 0.7rem;
  border-radius: 8px;
  font-size: 0.85rem;
  margin-bottom: 1.25rem;
  text-align: center;
  width: 100%;
  max-width: 280px;
}