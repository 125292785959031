

.user-management {
  padding: 20px;
  background: linear-gradient(135deg, #f5f7fa 0%, #e0e8ff 100%);
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);

  h2 {
    color: #1a237e;
    margin-bottom: 20px;
    font-size: 1.25rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.75px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      width: 30px;
      height: 2px;
      background: linear-gradient(to right, #ff4081, #3f51b5);
      border-radius: 1px;
    }
  }

  .error-message {
    color: #ff4081;
    margin-bottom: 10px;
    text-align: center;
    font-weight: 400;
  }

  .add-user-button {
    background: linear-gradient(to right, #ff4081, #3f51b5);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
    transition: all 0.2s ease;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(255, 64, 129, 0.2);
    }
  }

  .add-user-dialog {
    background-color: white;
    border: 1px solid #c5cae9;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 8px rgba(63, 81, 181, 0.08);

    h3 {
      margin-bottom: 15px;
      color: #1a237e;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 10px;

      input, select {
        padding: 8px;
        border: 1px solid #c5cae9;
        border-radius: 4px;
        font-family: 'Poppins', sans-serif;
      }

      button {
        padding: 8px 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.2s ease;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.5px;

        &[type="submit"] {
          background: linear-gradient(to right, #ff4081, #3f51b5);
          color: white;

          &:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 8px rgba(255, 64, 129, 0.2);
          }
        }

        &[type="button"] {
          background-color: #c5cae9;
          color: #1a237e;

          &:hover {
            background-color: #9fa8da;
          }
        }
      }
    }
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;

    th, td {
      border: 1px solid #c5cae9;
      padding: 12px;
      text-align: left;
    }

    th {
      background-color: #3f51b5;
      color: white;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }

    tr {
      background-color: white;
      transition: all 0.2s ease;

      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 4px 10px rgba(63, 81, 181, 0.1);
      }
    }
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.device-management, .user-management {
  animation: fadeIn 0.3s ease-in-out;
}