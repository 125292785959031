@import 'variables';

body {
  font-family: $font-family;
  font-size: $font-size-base;
  color: $text-color;
  background-color: $background-color;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: $spacing-large;
}

.btn {
  display: inline-block;
  padding: $spacing-small $spacing-medium;
  background-color: $primary-color;
  color: white;
  border: none;
  border-radius: $border-radius;
  cursor: pointer;
  transition: background-color $transition-speed;

  &:hover {
    background-color: darken($primary-color, 10%);
  }
}

.form-group {
  margin-bottom: $spacing-medium;

  label {
    display: block;
    margin-bottom: $spacing-small;
  }

  input {
    width: 100%;
    padding: $spacing-small;
    border: 1px solid lighten($text-color, 50%);
    border-radius: $border-radius;
    font-size: $font-size-base;
  }
}

.error-message {
  color: $error-color;
  font-size: $font-size-small;
  margin-top: $spacing-small;
}