@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

// Modern color palette with gradients
$primary-gradient: linear-gradient(135deg, #6366f1 0%, #FF7676 100%);
$secondary-gradient: linear-gradient(135deg, #f1f5f9 0%, #e2e8f0 100%);
$accent-gradient: linear-gradient(135deg, #FF5252 0%, #FF8A80 100%);
$text-color: #1e293b;
$light-text-color: #64748b;
$danger-color: #FF3D00;
$success-color: #00C853;
$primary-color: #FF3E3E;
$sidebar-width: 200px;
$header-height: 70px;
$transition-speed: 0.3s;
$secondary-color: #e2e8f0;

@mixin box-shadow {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

@mixin hover-transform {
  transition: transform $transition-speed ease;
  &:hover {
    transform: translateY(-2px);
  }
}

body {
  font-family: 'Poppins', sans-serif;
  background: $secondary-gradient;
  color: $text-color;
}

.top-bar-logo {
  height: 40px;
  margin-left: 15px;
}

.sidebar-logo {
  max-width: 80%;
  height: auto;
  margin-bottom: 20px;
}

.admin-dashboard {
  display: flex;
  min-height: 100vh;
  transition: all $transition-speed ease;

  &.sidebar-closed {
    .dashboard-content {
      margin-left: 70px;
    }
  }
}

.sidebar {
  width: $sidebar-width;
  background: $primary-gradient;
  @include box-shadow;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1000;
  overflow-y: auto;
  padding-bottom: 50px;

  .sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      margin: 0;
      color: #fff;
    }

    .toggle-btn {
      background: none;
      border: none;
      font-size: 1.2rem;
      cursor: pointer;
      color: #fff;
      @include hover-transform;
    }
  }

  .sidebar-nav {
    padding: 20px 0;

    .nav-item {
      display: flex;
      align-items: center;
      padding: 15px 20px;
      color: rgba(255, 255, 255, 0.7);
      text-decoration: none;
      transition: all $transition-speed ease;

      &:hover, &.active {
        background-color: rgba(255, 255, 255, 0.1);
        color: #fff;
      }

      svg {
        margin-right: 10px;
        font-size: 1.2rem;
      }

      span {
        transition: opacity $transition-speed ease;
      }
    }

    .logout-btn {
      display: flex;
      align-items: center;
      padding: 15px 20px;
      color: rgba(255, 255, 255, 0.7);
      text-decoration: none;
      transition: all $transition-speed ease;
      background: none;
      border: none;
      cursor: pointer;
      width: 100%;
      text-align: left;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        color: #fff;
      }

      svg {
        margin-right: 10px;
        font-size: 1.2rem;
      }
    }
  }
}

.dashboard-content {
  flex: 1;
  margin-left: $sidebar-width;
  padding: 30px;
  transition: all $transition-speed ease;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  background: $secondary-color;
  padding: 20px 30px;
  border-radius: 12px;
  @include box-shadow;

  h1 {
    font-size: 2rem;
    font-weight: 600;
    margin: 0;
    background: $primary-gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .header-actions {
    display: flex;
    align-items: center;

    .notification-btn {
      position: relative;
      background: none;
      border: none;
      font-size: 1.2rem;
      margin-right: 20px;
      cursor: pointer;
      color: $text-color;
      @include hover-transform;

      .notification-badge {
        position: absolute;
        top: -5px;
        right: -5px;
        background-color: $danger-color;
        color: #fff;
        border-radius: 50%;
        padding: 2px 5px;
        font-size: 0.7rem;
      }
    }

    .profile-btn {
      background: none;
      border: none;
      cursor: pointer;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        @include hover-transform;
      }
    }

    .logout-btn {
      background: none;
      border: none;
      cursor: pointer;
      color: $text-color;
      margin-left: 20px;
      font-size: 1rem;
      transition: all $transition-speed ease;

      &:hover {
        transform: translateY(-2px);
      }

      svg {
        margin-right: 10px;
        font-size: 1rem;
      }
    }
  }
}

.dashboard-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
}

.dashboard-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

.dashboard-card {
  background-color: $secondary-color;
  border-radius: 12px;
  padding: 25px;
  @include box-shadow;
  display: flex;
  align-items: center;
  @include hover-transform;

  .card-icon {
    font-size: 2.5rem;
    color: $primary-color;
    margin-right: 25px;
  }

  .card-content {
    h3 {
      font-size: 1.1rem;
      font-weight: 500;
      margin: 0 0 5px;
      color: $light-text-color;
    }

    p {
      font-size: 1.8rem;
      font-weight: 600;
      margin: 0;
      color: $primary-color;
    }
  }
}

.completed-inspections {
  background-color: $secondary-color;
  border-radius: 12px;
  padding: 25px;
  @include box-shadow;

  h3 {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: $primary-color;
  }

  .table-container {
    overflow-x: auto;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;

    th, td {
      padding: 15px;
      text-align: left;
    }

    th {
      font-weight: 600;
      background-color: #fff;
      color: $primary-color;
      border-radius: 8px;
    }

    tr {
      transition: background-color $transition-speed ease;

      &:hover {
        background-color: lighten($secondary-color, 2%);
      }
    }

    td {
      background-color: #fff;
      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    .status-badge {
      padding: 6px 12px;
      border-radius: 20px;
      font-size: 0.9rem;
      font-weight: 500;

      &.pass {
        background-color: $success-color;
        color: #fff;
      }

      &.fail {
        background-color: $danger-color;
        color: #fff;
      }
    }
  
    .view-report-btn {
      background-color: $primary-color;
      color: #fff;
      border: none;
      padding: 8px 15px;
      border-radius: 8px;
      cursor: pointer;
      transition: all $transition-speed ease;
      @include hover-transform;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }
  }
}

.inspection-report-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .report-content {
    background-color: #fff;
    padding: 2.5rem;
    border-radius: 12px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    @include box-shadow;

    .close-btn {
      float: right;
      font-size: 1.5rem;
      cursor: pointer;
      background: none;
      border: none;
      color: $primary-color;
      @include hover-transform;
    }

    h3 {
      margin-top: 0;
      color: $primary-color;
      font-size: 1.5rem;
    }

    .checklist, .photos, .documents {
      margin-top: 1.5rem;
    }

    .photo-gallery {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 1rem;

      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 8px;
        transition: transform $transition-speed ease;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

@media (max-width: 768px) {
  .admin-dashboard {
    flex-direction: column;
  }

  .sidebar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    height: auto;
    width: 100%;
    padding-bottom: 50px;

    .sidebar-nav {
      display: flex;
      justify-content: space-around;
      padding: 10px 0;
    }

    .sidebar-header {
      display: none;
    }
  }

  .dashboard-content {
    margin-left: 0;
    margin-bottom: 70px;
    padding: 20px;
  }

  .dashboard-header {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 20px;
  
    .header-actions {
      margin-top: 15px;
      align-self: flex-end;
    }
  }
  
  .dashboard-cards {
    grid-template-columns: 1fr;
  }
  
  .completed-inspections {
    overflow-x: auto;
  }
  

  h1 {
    font-size: 2rem;
    font-weight: 600;
    margin: 0;
    background: $primary-gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .header-actions {
    display: flex;
    align-items: center;

    .notification-btn {
      position: relative;
      background: none;
      border: none;
      font-size: 1.2rem;
      margin-right: 20px;
      cursor: pointer;
      color: $text-color;
      @include hover-transform;

      .notification-badge {
        position: absolute;
        top: -5px;
        right: -5px;
        background-color: $danger-color;
        color: #fff;
        border-radius: 50%;
        padding: 2px 5px;
        font-size: 0.7rem;
      }
    }

    .profile-btn {
      background: none;
      border: none;
      cursor: pointer;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        @include hover-transform;
      }
    }
  }
}

.dashboard-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
}

.dashboard-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

.dashboard-card {
  background-color: #fff;
  border-radius: 12px;
  padding: 25px;
  @include box-shadow;
  display: flex;
  align-items: center;
  @include hover-transform;

  .card-icon {
    font-size: 2.5rem;
    color: $primary-color;
    margin-right: 25px;
  }

  .card-content {
    h3 {
      font-size: 1.1rem;
      font-weight: 500;
      margin: 0 0 5px;
      color: $light-text-color;
    }

    p {
      font-size: 1.8rem;
      font-weight: 600;
      margin: 0;
      color: $primary-color;
    }
  }
}

.completed-inspections {
  background-color: #fff;
  border-radius: 12px;
  padding: 25px;
  @include box-shadow;

  h3 {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: $primary-color;
  }

  .table-container {
    overflow-x: auto;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;

    th, td {
      padding: 15px;
      text-align: left;
    }

    th {
      font-weight: 600;
      background-color: $secondary-color;
      color: $primary-color;
      border-radius: 8px;
    }

    tr {
      transition: background-color $transition-speed ease;

      &:hover {
        background-color: lighten($secondary-color, 2%);
      }
    }

    td {
      background-color: #fff;
      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    .status-badge {
      padding: 6px 12px;
      border-radius: 20px;
      font-size: 0.9rem;
      font-weight: 500;

      &.pass {
        background-color: $success-color;
        color: #fff;
      }

      &.fail {
        background-color: $danger-color;
        color: #fff;
      }
    }

    .view-report-btn {
      background-color: $primary-color;
      color: #fff;
      border: none;
      padding: 8px 15px;
      border-radius: 8px;
      cursor: pointer;
      transition: all $transition-speed ease;
      @include hover-transform;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }
  }
}

.inspection-report-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .report-content {
    background-color: white;
    padding: 2.5rem;
    border-radius: 12px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    @include box-shadow;

    .close-btn {
      float: right;
      font-size: 1.5rem;
      cursor: pointer;
      background: none;
      border: none;
      color: $primary-color;
      @include hover-transform;
    }

    h3 {
      margin-top: 0;
      color: $primary-color;
      font-size: 1.5rem;
    }

    .checklist, .photos, .documents {
      margin-top: 1.5rem;
    }

    .photo-gallery {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 1rem;

      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 8px;
        transition: transform $transition-speed ease;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

@media (max-width: 768px) {
  .admin-dashboard {
    flex-direction: column;
  }

  .sidebar {
    width: $sidebar-width;
    background: $primary-gradient;
    @include box-shadow;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1000;
    overflow-y: auto;
    padding-bottom: 50px;
    transition: transform $transition-speed ease;  
    &.open {
      transform: translateX(0);
    }
  
    &.closed {
      transform: translateX(-$sidebar-width);
    }

    .sidebar-nav {
      display: flex;
      justify-content: space-around;
      padding: 10px 0;
    }

    .sidebar-header, .sidebar-footer {
      display: none;
    }

    .nav-item {
      flex-direction: column;
      padding: 10px;
      font-size: 0.8rem;

      svg {
        margin-right: 0;
        margin-bottom: 5px;
      }
    }
  }

  .admin-dashboard {
    display: flex;
    min-height: 100vh;
    transition: all $transition-speed ease;
  
    &.sidebar-closed {
      .dashboard-content {
        margin-left: $sidebar-width;
      }
    }
  
    &.sidebar-open {
      .dashboard-content {
        margin-left: 70px;
      }
    }
  }
  .dashboard-content {
    margin-left: 0;
    margin-bottom: 70px;
    padding: 20px;
    transition: margin-left $transition-speed ease;

    .sidebar-closed & {
      margin-left: 0;
    }
  }
  .dashboard-header {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 20px;

    .header-actions {
      margin-top: 15px;
      align-self: flex-end;
    }
  }

  .dashboard-cards {
    grid-template-columns: 1fr;
  }

  .completed-inspections {
    overflow-x: auto;
  }
}
.recent-inspections {
  background-color: $secondary-color;
  border-radius: 12px;
  padding: 25px;
  @include box-shadow;

  h3 {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: $primary-color;
  }

  .table-container {
    overflow-x: auto;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;

    th, td {
      padding: 15px;
      text-align: left;
    }

    th {
      font-weight: 600;
      background-color: $secondary-color;
      color: $primary-color;
      border-radius: 8px;
    }

    tr {
      transition: background-color $transition-speed ease;

      &:hover {
        background-color: lighten($secondary-color, 2%);
      }
    }

    td {
      background-color: #fff;
      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    .status-badge {
      padding: 6px 12px;
      border-radius: 20px;
      font-size: 0.9rem;
      font-weight: 500;

      &.pass {
        background-color: $success-color;
        color: #fff;
      }

      &.fail {
        background-color: $danger-color;
        color: #fff;
      }
    }

    .view-report-btn {
      background-color: $primary-color;
      color: #fff;
      border: none;
      padding: 8px 15px;
      border-radius: 8px;
      cursor: pointer;
      transition: all $transition-speed ease;
      @include hover-transform;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }
  }

  .error-message {
    color: $danger-color;
    margin-bottom: 15px;
  }
}
