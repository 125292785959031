.inspection-report {
    font-family: 'Arial', sans-serif;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .report-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 2px solid #e0e0e0;
  }
  
  .report-header h2 {
    font-size: 24px;
    color: #333;
  }
  
  .report-actions button {
    margin-left: 10px;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .export-btn {
    background-color: #4CAF50;
    color: white;
  }
  
  .close-btn {
    background-color: #f44336;
    color: white;
  }
  
  .report-section {
    background-color: white;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .report-section h3 {
    color: #2196F3;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    margin-bottom: 15px;
  }
  
  .checklist ul {
    list-style-type: none;
    padding: 0;
  }
  
  .checklist li {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  
  .checklist .fa-check-circle {
    color: #4CAF50;
    margin-right: 10px;
  }
  
  .checklist .fa-times-circle {
    color: #f44336;
    margin-right: 10px;
  }
  
  .photo-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
  }
  
  .photo-container {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .photo-container img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .image-error {
    text-align: center;
    padding: 20px;
    background-color: #ffebee;
    color: #f44336;
  }
  
  .documents ul {
    list-style-type: none;
    padding: 0;
  }
  
  .documents li {
    margin-bottom: 10px;
  }
  
  .document-download-link {
    text-decoration: none;
    color: #2196F3;
    display: flex;
    align-items: center;
  }
  
  .document-download-link:hover {
    text-decoration: underline;
  }
  
  .document-download-link .fa-file-download {
    margin-right: 10px;
  }
  
  .result {
    display: flex;
    align-items: center;
  }
  
  .status-icon {
    margin-right: 10px;
    font-size: 1.2em;
  }
  
  .status-icon.approved {
    color: #4CAF50;
  }
  
  .status-icon.rejected {
    color: #f44336;
  }
  
  .status-icon.maintenance {
    color: #FFC107;
  }
  
  @media (max-width: 600px) {
    .inspection-report {
      padding: 10px;
    }
  
    .report-header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .report-actions {
      margin-top: 10px;
    }
  
    .photo-gallery {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
  }