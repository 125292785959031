.analytics-container {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .analytics-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .analytics-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .analytics-card {
    flex: 1;
    min-width: 200px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  
    h3 {
      font-size: 18px;
      margin-bottom: 10px;
      color: #555;
    }
  
    p {
      font-size: 24px;
      font-weight: bold;
      color: #333;
    }
  }
  
  .analytics-chart {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .chart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  
    h3 {
      font-size: 18px;
      color: #555;
    }
  }
  
  .chart-type-selector {
    display: flex;
    gap: 10px;
  }
  
  .chart-type-btn {
    padding: 8px 12px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  
    &:hover {
      background-color: #e0e0e0;
    }
  
    &.active {
      background-color: #8884d8;
      color: white;
    }
  }
  
  .analytics-loading,
  .analytics-error {
    text-align: center;
    padding: 20px;
    font-size: 18px;
    color: #555;
  }