// CustomerDashboard.scss

// Define variables
$cd-primary-color: #8B5CF6; // New primary color
$cd-secondary-color: #F3F4F6; // Lighter background
$cd-text-color: #1E293B; // Darker text color
$cd-border-color: #D1D5DB; // Softer border color
$cd-white: #FFFFFF;
$cd-notification-red: #EF4444; // More vibrant red
$cd-read-color: #34D399; // Fresh green
$cd-accent-color: #F59E0B; // Warm orange accent
$cd-gradient-start: #8B5CF6; // Primary color
$cd-gradient-end: #EC4899; // New gradient end color
$cd-card-bg: #F9FAFB; // Lighter card background
$cd-card-shadow: rgba(0, 0, 0, 0.05); // Softer shadow
.top-bar-logo {
    height: 40px;
    margin-left: 15px;
  }
  
  .sidebar-logo {
    max-width: 80%;
    height: auto;
    margin-bottom: 20px;
  }
  
// Define fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.cd-customer-dashboard {
  font-family: 'Poppins', sans-serif;
  background-color: $cd-secondary-color;
  color: $cd-text-color;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .cd-dashboard-header {
    background: linear-gradient(135deg, $cd-gradient-start, $cd-gradient-end);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    padding: 1.5rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cd-logo {
      h1 {
        font-size: 2.4rem;
        font-weight: 700;
        color: $cd-white;
        margin: 0;
        letter-spacing: -1px;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
      }
    }

    .cd-user-actions {
      display: flex;
      align-items: center;
      gap: 2.5rem;

      .cd-icon-button {
        background: rgba($cd-white, 0.2);
        border: none;
        font-size: 1.6rem;
        color: $cd-white;
        cursor: pointer;
        position: relative;
        transition: all 0.3s ease;
        padding: 0.5rem;
        border-radius: 50%;

        &:hover {
          background: rgba($cd-white, 0.3);
          transform: translateY(-2px);
        }

        .cd-notification-badge {
          position: absolute;
          top: -8px;
          right: -8px;
          background-color: $cd-notification-red;
          color: $cd-white;
          font-size: 0.75rem;
          padding: 2px 6px;
          border-radius: 12px;
          font-weight: 600;
        }
      }

      .cd-user-info {
        display: flex;
        align-items: center;
        gap: 1rem;
        font-weight: 500;
        font-size: 1.1rem;
        color: $cd-white;

        svg {
          font-size: 1.4rem;
          color: $cd-white;
        }
      }

      .cd-btn-logout {
        background-color: rgba($cd-white, 0.2);
        color: $cd-white;
        border: none;
        padding: 0.75rem 1.5rem;
        border-radius: 30px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.75rem;
        transition: all 0.3s ease;
        font-weight: 600;
        font-size: 1rem;
        text-transform: uppercase;
        letter-spacing: 0.5px;

        &:hover {
          background-color: rgba($cd-white, 0.3);
          transform: translateY(-2px);
          box-shadow: 0 4px 15px rgba($cd-white, 0.2);
        }
      }
    }
  }

  .cd-dashboard-content {
    display: flex;
    flex: 1;
  }

  .cd-sidebar {
    width: 280px;
    background-color: $cd-secondary-color;
    box-shadow: 2px 0 20px rgba(0, 0, 0, 0.05);
    padding: 2.5rem 0;
    border-radius: 0 20px 20px 0;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 0.5rem;

        .cd-nav-link {
          display: block;
          padding: 1rem 2.5rem;
          color: $cd-text-color;
          text-decoration: none;
          transition: all 0.3s ease;
          font-weight: 500;
          font-size: 1.1rem;
          border-left: 4px solid transparent;

          &:hover, &.active {
            background-color: rgba($cd-primary-color, 0.1);
            color: $cd-primary-color;
            border-left-color: $cd-primary-color;
          }
        }
      }
    }
  }

  .cd-main-content {
    flex: 1;
    padding: 3rem;

    h2 {
      margin-top: 0;
      margin-bottom: 2.5rem;
      font-weight: 700;
      font-size: 2.4rem;
      color: $cd-primary-color;
      letter-spacing: -0.5px;
    }
  }

  .cd-dashboard-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2.5rem;
    margin-bottom: 4rem;

    .cd-card {
      background-color: $cd-card-bg;
      border-radius: 20px;
      box-shadow: 0 10px 30px $cd-card-shadow;
      padding: 2.5rem;
      text-align: center;
      transition: all 0.3s ease;
      position: relative;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4px;
        background: linear-gradient(to right, $cd-primary-color, $cd-accent-color);
      }

      &:hover {
        transform: translateY(-10px) rotate(2deg);
        box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
      }

      h3 {
        font-size: 1.2rem;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 1rem;
        color: $cd-text-color;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      .cd-card-value {
        font-size: 3.2rem;
        font-weight: 700;
        color: $cd-primary-color;
        margin: 0;
        line-height: 1;
      }
    }
  }

  .cd-notifications-panel {
    position: fixed;
    top: 80px;
    right: 30px;
    width: 380px;
    background-color: $cd-white;
    border-radius: 20px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    max-height: calc(100vh - 120px);
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .cd-notifications-header {
      padding: 1.5rem 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $cd-border-color;
      background: linear-gradient(135deg, $cd-gradient-start, $cd-gradient-end);

      h3 {
        margin: 0;
        font-size: 1.5rem;
        font-weight: 700;
        color: $cd-white;
      }

      .cd-clear-notifications {
        background: rgba($cd-white, 0.2);
        border: none;
        color: $cd-white;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 1rem;
        font-weight: 500;
        transition: all 0.3s ease;
        padding: 0.5rem 1rem;
        border-radius: 20px;

        &:hover {
          background: rgba($cd-white, 0.3);
          transform: translateX(-3px);
        }
      }
    }

    .cd-notifications-list {
      overflow-y: auto;
      max-height: calc(100vh - 240px);
      padding: 1.5rem;

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
      }

      .cd-notification-item {
        padding: 1.25rem;
        border-bottom: 1px solid $cd-border-color;
        font-size: 1rem;
        transition: all 0.3s ease;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 12px;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          background-color: rgba($cd-primary-color, 0.05);
          transform: translateX(5px);
        }

        &.read {
          opacity: 0.6;
        }

        p {
          margin: 0;
          flex: 1;
          line-height: 1.5;
        }

        .cd-mark-read {
          background: none;
          border: none;
          color: $cd-read-color;
          cursor: pointer;
          font-size: 1.2rem;
          transition: all 0.3s ease;

          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }

    .cd-no-notifications {
      text-align: center;
      color: $cd-text-color;
      opacity: 0.7;
      padding: 2rem;
      font-style: italic;
    }
  }
}
