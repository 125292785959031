@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

// Variables
$primary-color: #3050ff; // Blue
$secondary-color: #ff3050; // Red
$text-color: #333;
$background-color: #f0f2f5;
$white: #ffffff;

// Mixins
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin box-shadow {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

@mixin transition {
  transition: all 0.3s ease;
}

// Global Styles
body {
  font-family: 'Roboto', sans-serif;
  background-color: $background-color;
  color: $text-color;
  line-height: 1.6;
}

.inspection-report {
  background-color: $white;
  border-radius: 12px;
  @include box-shadow;
  max-width: 1000px;
  margin: 3rem auto;
  overflow: hidden;
}

// Header Styles
.report-header {
  background: linear-gradient(135deg, #5880df, darken(#f0f2f5, 35%));
  color: $white;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
    font-size: 2.2rem;
    font-weight: 700;
    letter-spacing: 0.5px;
  }

  .report-actions {
    display: flex;
    gap: 1rem;

    button {
      background: none;
      border: none;
      color: $white;
      cursor: pointer;
      font-size: 1rem;
      @include transition;

      &:hover {
        transform: translateY(-2px);
      }
    }

    .export-btn {
      @include flex-center;
      background-color: $secondary-color;
      padding: 0.7rem 1.5rem;
      border-radius: 50px;
      font-weight: 500;

      svg {
        margin-right: 0.5rem;
      }
    }

    .close-btn {
      @include flex-center;
      background-color: rgba($white, 0.2);
      padding: 0.7rem;
      border-radius: 50%;
      width: 40px;
      height: 40px;
    }
  }
}

// Content Styles
.report-content {
  padding: 3rem;
}

.report-section {
  margin-bottom: 3rem;

  h3 {
    color: $primary-color;
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
    font-weight: 700;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 50px;
      height: 4px;
      background-color: $secondary-color;
      border-radius: 2px;
    }
  }

  p {
    margin: 1rem 0;
    font-size: 1.1rem;
  }

  strong {
    font-weight: 500;
    color: $primary-color;
  }
}

// Status Icons
.status-icon {
  font-size: 1.4rem;
  margin-right: 0.7rem;

  &.approved {
    color: #2ecc71;
  }

  &.rejected {
    color: $secondary-color;
  }

  &.maintenance {
    color: #f39c12;
  }
}

// Checklist Styles
.checklist {
  list-style-type: none;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    font-size: 1.1rem;

    svg {
      margin-right: 1rem;
      font-size: 1.4rem;
    }

    &.checked {
      color: #2ecc71;
    }

    &.unchecked {
      color: $secondary-color;
    }
  }
}

// Photo Gallery Styles
.photo-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;

  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
    @include box-shadow;
    @include transition;

    &:hover {
      transform: scale(1.05);
    }
  }
}

// Document List Styles
.document-list {
  list-style-type: none;
  padding: 0;

  li {
    margin-bottom: 1rem;

    .document-download-btn {
      color: $primary-color;
      text-decoration: none;
      display: flex;
      align-items: center;
      @include transition;
      background: none;
      border: none;
      cursor: pointer;
      padding: 0.5rem 1rem;
      border-radius: 4px;
      font-size: 1.1rem;

      svg {
        margin-right: 0.7rem;
      }

      &:hover {
        background-color: rgba($primary-color, 0.1);
        color: darken($primary-color, 10%);
      }
    }
  }
}

// Responsive Design
@media (max-width: 768px) {
  .inspection-report {
    margin: 1.5rem;
  }

  .report-header {
    flex-direction: column;
    align-items: flex-start;

    h2 {
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }

    .report-actions {
      margin-top: 1rem;
    }
  }

  .report-content {
    padding: 1.5rem;
  }

  .photo-gallery {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

    img {
      height: 150px;
    }
  }
}

// Animations
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.inspection-report {
  animation: fadeIn 0.5s ease-in-out;
}

.report-section {
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;

  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      animation-delay: #{$i * 0.1}s;
    }
  }
}