.device-management {
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  padding: 0.5rem;
  border-radius: 6px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

  h2 {
    color: #2c3e50;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 50%;
      transform: translateX(-50%);
      width: 15px;
      height: 1px;
      background: linear-gradient(to right, #3498db, #2ecc71);
    }
  }

  .error-message {
    background-color: #ff6b6b;
    color: white;
    padding: 0.2rem;
    border-radius: 2px;
    margin-bottom: 0.3rem;
    text-align: center;
    font-size: 0.7rem;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 3px;
    font-size: 0.7rem;

    th, td {
      padding: 0.2rem;
      text-align: center;
    }

    thead {
      tr {
        background-color: #3498db;
        color: white;

        th {
          font-weight: 500;
          text-transform: uppercase;
          letter-spacing: 0.2px;
        }
      }
    }

    tbody {
      tr {
        background-color: white;
        transition: all 0.1s ease;
        
        &:hover {
          transform: translateY(-1px);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
        }

        &:nth-child(even) {
          background-color: #f7f7f7;
        }

        td {
          border-top: 1px solid #ecf0f1;
          border-bottom: 1px solid #ecf0f1;

          &:first-child {
            border-left: 1px solid #ecf0f1;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
          }

          &:last-child {
            border-right: 1px solid #ecf0f1;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
          }
        }
      }
    }
  }

  .device-qr-code {
    max-width: 60px;
    max-height: 60px;
  }

  button {
    background: linear-gradient(to right, #df8b64, #cf271b);
    color: white;
    border: none;
    padding: 0.6rem 0.2rem;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.1s ease;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    font-size: 0.6rem;

    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 1px 2px rgba(52, 152, 219, 0.1);
    }
  }

  .assign-inspection {
    background-color: white;
    padding: 0.3rem;
    border-radius: 3px;
    margin-top: 0.3rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);

    h3 {
      color: #2c3e50;
      margin-bottom: 0.3rem;
      font-size: 0.75rem;
    }

    select, input[type="date"] {
      width: 100%;
      padding: 0.1rem;
      margin-bottom: 0.2rem;
      border: 1px solid #bdc3c7;
      border-radius: 2px;
      font-family: 'Poppins', sans-serif;
      font-size: 0.7rem;
    }

    button {
      width: 100%;
      padding: 0.2rem;
      font-size: 0.7rem;
    }
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.device-management {
  animation: fadeIn 0.2s ease-in-out;
}